.wellcome {
  @apply flex flex-col w-full min-h-screen py-12;
  &-bg {
    background: url("../../assets/images/wellcome-bg.jpg") no-repeat center
      center / cover;
  }
  &__flexcenter {
    @apply flex items-center justify-center flex-col;
  }
  p {
    @apply text-sm mt-3 text-center;
  }
  h2 {
    @apply md:text-f45 text-f32 md:leading-[56px] leading-9 text-center font-normal;
    background: linear-gradient(
      180deg,
      #000000 40.01%,
      rgba(0, 0, 0, 0.25) 126.34%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  h3 {
    @apply text-f32 font-semibold text-center mt-10;
    background: linear-gradient(
      to right,
      #000000 25%,
      rgba(0, 0, 0, 0.25) 70%,
      #000000 100%
    );
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3.5s ease-in-out infinite;
  }
  h4 {
    @apply md:text-f20 text-sm text-center mt-4;
    background: linear-gradient(
      180deg,
      #000000 40.01%,
      rgba(0, 0, 0, 0.25) 126.34%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &__social {
    @apply flex flex-wrap items-center justify-center gap-x-7 mt-10;
    img {
      @apply relative duration-300 top-0;
    }
    a:hover {
      img {
        @apply -top-1;
      }
    }
  }
}
