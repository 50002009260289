.animated {
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

@for $i from 1 through 9 {
  .animated-delay-#{$i} {
    animation-delay: #{$i}00ms;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}
.zoomOut {
  animation-name: zoomOut;
}

@keyframes buttonhover {
  to {
    transform: rotate(360deg);
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes logo {
  from {
    transform: translateY(-0.75rem);
  }
  50% {
    transform: translateY(0.75rem);
  }
  to {
    transform: translateY(-0.75rem);
  }
}
.logo {
  animation-name: logo;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 9s;
}
